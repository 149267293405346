/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import CategoricalScheme from '../../CategoricalScheme';
import { ColorSchemeGroup } from '../../types';

const schemes = [
  {
    id: 'BAGoneBlue',
    label: 'BAG Colors only one blue',
    group: ColorSchemeGroup.Custom,
    colors: ['#C0D8F0'],
  },
  {
    id: 'BAGblue',
    label: 'BAG Colors Blue',
    group: ColorSchemeGroup.Custom,
    colors: ['#C0D8F0', '#81B1E2', '#428BD3', '#173D63', '#112E4A', '#0C1F31'],
  },
  {
    id: 'BAGmanyBlue',
    label: 'BAG Colors 30x Blue',
    group: ColorSchemeGroup.Custom,
    colors: [
      '#C0D8F0',
      '#BAD3ED',
      '#B5CEEA',
      '#AFCAE7',
      '#A9C5E4',
      '#A4C0E0',
      '#9EBBDD',
      '#98B6DA',
      '#93B1D7',
      '#8DADD4',
      '#87A8D1',
      '#82A3CE',
      '#7C9ECB',
      '#7699C8',
      '#7194C5',
      '#6B90C1',
      '#668BBE',
      '#6086BB',
      '#5A81B8',
      '#557CB5',
      '#4F77B2',
      '#4973AF',
      '#446EAC',
      '#3E69A9',
      '#3864A6',
      '#335FA2',
      '#2D5A9F',
      '#27569C',
      '#225199',
      '#1C4C96',
    ],
  },
  {
    id: 'BAGblueMulti',
    label: 'BAG Colors 30x multi',
    group: ColorSchemeGroup.Custom,
    colors: [
      '#428BD3',
      '#173D63',
      '#5592AD',
      '#984D65',
      '#8682C9',
      '#C1327F',
      '#44647A',
      '#15858C',
      '#719B77',
      '#F18820',
      '#78991E',
      '#B98606',
      '#EB5957',
      '#B6B4DF',
      '#363274',
      '#C68FA1',
      '#B01715',
      '#99BECE',
      '#49DBE4',
      '#84A4BA',
      '#BBDF5B',
      '#AAC3AD',
      '#F7B879',
      '#580C0B',
      '#F9C646',
      '#60193F',
      '#0C1F31',
      '#22323D',
      '#374F3B',
      '#804408',
    ],
  },
  {
    id: 'BAG7xblue',
    label: 'BAG Colours 7x Blue',
    group: ColorSchemeGroup.Custom,
    colors: [
      '#C0D8F0',
      '#A5C1E1',
      '#89A9D2',
      '#6E92C3',
      '#537BB4',
      '#3763A5',
      '#1C4C96',
    ],
  },
  {
    id: 'BAG13xblue',
    label: 'BAG Colours 13x Blue',
    group: ColorSchemeGroup.Custom,
    colors: [
      '#C0D8F0',
      '#B2CCE9',
      '#A5C1E1',
      '#97B5DA',
      '#89A9D2',
      '#7C9ECB',
      '#6E92C3',
      '#6086BC',
      '#537BB4',
      '#456FAD',
      '#3763A5',
      '#2A589E',
      '#1C4C96',
    ],
  },
  {
    id: 'BAGwebtol',
    label: 'BAG Colors Webtool',
    group: ColorSchemeGroup.Custom,
    isDefault: true,
    colors: [
      '#B01715',
      '#984D65',
      '#C1327F',
      '#8682C9',
      '#173D63',
      '#5592AD',
      '#44647A',
      '#15858C',
      '#719B77',
      '#78991E',
      '#F18820',
      '#B98606',
    ],
  },
  {
    id: 'BAGred',
    label: 'BAG Colors Red',
    group: ColorSchemeGroup.Custom,
    colors: ['#F8C8C7', '#F2908F', '#EB5957', '#B01715', '#841110', '#580C0B'],
  },
].map(s => new CategoricalScheme(s));

export default schemes;
